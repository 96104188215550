const BASE_URL = `${process.env.REACT_APP_API_USERS_BASE_URL}/Users`

export const getUser = async (token) => {
  const response = await fetch(BASE_URL, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response
}

export const createUser = async (token, user) => {
  const response = await fetch(BASE_URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(user)
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response
}