import React, { useState } from 'react';
import TableItemContextMenu from 'components/common/tableItemContextMenu';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ThreeDotsIcon } from "assets/icons/ico_three_dots_green.svg";
import { ReactComponent as DownloadIcon } from 'assets/icons/ico_download.svg';
import { ReactComponent as MoneyIcon } from 'assets/icons/ico_money.svg';

const InvoiceItem = ({ invoice }) => {
    const { t } = useTranslation();
    const [showMenu, setShowMenu] = useState();

    const openPdf = () => {
        window.open(invoice.invoicePdf, '_blank');
    }

    const openInvoice = () => {
        window.open(invoice.invoiceUrl, '_blank');
    }

    return (
        <tr className="h-16 border-b-[1px] border-airpark-gray-300">
            <td className="pl-6 whitespace-nowrap">{invoice.invoiceExtId}</td>
            <td className="pl-6 whitespace-nowrap">{invoice.invoiceDate?.substring(0,10)}</td>
            <td className="pl-6 whitespace-nowrap">{invoice.dueDate?.substring(0,10)}</td>
            <td className="pl-6 whitespace-nowrap">{invoice.amountIncVat}</td>
            <td className="pl-6 whitespace-nowrap">{invoice.vat}</td>
            <td className="pl-6 whitespace-nowrap">{invoice.invoiceStatus}</td>
            <td className="border-l-[1px] border-airpark-gray-300 w-[72px] min-w-[72px] relative">
                <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); setShowMenu(!showMenu); }}>
                    <ThreeDotsIcon className="block" />
                </button>
                {showMenu &&
                    <TableItemContextMenu onCloseClick={() => { setShowMenu(false); }}>
                        <button className="flex items-center" onClick={(e) => { e.preventDefault(); openInvoice(); }} >
                            <MoneyIcon className="h-5 w-5" /><span className="ml-1">{t('invoicesTable.pay')}</span>
                        </button>
                        <button className="flex items-center" onClick={(e) => { e.preventDefault(); openPdf(); }} >
                            <DownloadIcon className="h-5 w-5" /><span className="ml-1">{t('invoicesTable.downloadPdf')}</span>
                        </button>
                    </TableItemContextMenu>
                }
            </td>
        </tr>
    );
};

export default InvoiceItem;