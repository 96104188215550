import { GoogleMap, LoadScriptNext } from '@react-google-maps/api';
import React, { useState, useEffect } from 'react';
import googleMapStyle from 'styles/googleMapStyle';
import Loading from '../loading';

const ParkingSpotMap = ({ parkingAreas, setSelectedParkingArea, setMapPosition }) => {
    const mapContainerStyle = {
        width: '100%',
        maxWidth: '100%',
        height: '420px'
    };

    const [center, setCenter] = useState({ lat: 59, lng: 12 });
    const [zoom, setZoom] = useState(5);
    const [libraries] = useState(['drawing']);
    const [map, setMap] = useState();
    const [loadingUserPosition, setLoadingUserPosition] = useState(true);
    const [polygons, setPolygons] = useState([]);

    useEffect(() => {
        if (map && parkingAreas) {

            /* Clear any previous polygons */
            polygons.forEach((polygon) => {
                polygon.setMap(null);
            });

            let createdPolygons = [];

            parkingAreas.forEach((parkingArea) => {
                if (!parkingArea.location?.coordinates) {
                    return;
                }

                let polygonCoordinates = [];

                parkingArea.location.coordinates[0].forEach((c) => {
                    polygonCoordinates.push({ lat: c[0], lng: c[1] })
                });

                const polygon = new window.google.maps.Polygon({
                    paths: polygonCoordinates,
                    fillColor: parkingArea.availablity?.total > 0 ? '#33D085' : '#D1D1D1',
                    fillOpacity: '0.4',
                    strokeWeight: 3,
                    editable: false,
                    zIndex: 1,
                    strokeColor: parkingArea.availablity?.total > 0 ? '#33D085' : '#D1D1D1',
                    draggable: false,
                    cursor: 'pointer'
                });

                polygon.gm_bindings = {};
                polygon.gm_bindings['parkingArea'] = parkingArea;

                // Disable all click event listeners on the polygon
                window.google.maps.event.clearListeners(polygon, 'click');

                window.google.maps.event.addListener(polygon, "click", function (event) {
                    const selectedParkingArea = polygon.gm_bindings['parkingArea'];
                    setSelectedParkingArea(selectedParkingArea);
                    document.documentElement.scrollTop = document.documentElement.scrollHeight;
                });

                polygon.setMap(map);

                createdPolygons.push(polygon);
            });

            setPolygons(createdPolygons);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, parkingAreas]);

    const onLoadGoogleMap = (ref) => {
        setMap(ref);
        ref.setMapTypeId(window.google.maps.MapTypeId.ROADMAP);
        ref.setTilt(0);
    }

    const panTo = ({ lat, lng }) => {
        setCenter({ lat: lat, lng: lng });
        map.panTo({ lat, lng });
    }

    const handleMapCenterChanged = () => {
        if (!map) {
            return;
        }

        const center = map.getCenter();
        setMapPosition({ lat: center.lat(), lng: center.lng() });

        // Ignore initial values
        if (map.getZoom() == 5) {
            return;
        }

        // Store map position center in a cookie
        document.cookie = `mapPosition=${center.lat()},${center.lng()};max-age=31536000;path=/`;

        // Store map zoom in a cookie
        document.cookie = `mapZoom=${map.getZoom()};max-age=31536000;path=/`;
    }

    useEffect(() => {
        if (map) {
            // Get map position center from cookie and zoom from cookie, if they exist then update map
            const mapPositionCookie = document.cookie.split('; ').find(row => row.startsWith('mapPosition='));
            const mapZoomCookie = document.cookie.split('; ').find(row => row.startsWith('mapZoom='));

            if (mapPositionCookie && mapZoomCookie) {
                const mapPosition = mapPositionCookie.split('=')[1].split(',');
                const mapZoom = parseInt(mapZoomCookie.split('=')[1]);

                if (mapPosition.length === 2 && !isNaN(mapZoom)) {
                    panTo({ lat: parseFloat(mapPosition[0]), lng: parseFloat(mapPosition[1]) });
                    setZoom(mapZoom);
                }
            }
        }
    }, [map]);

    return (
        <>
            <div>
                <LoadScriptNext libraries={libraries} googleMapsApiKey="AIzaSyD6UTBXOfbCvJWhKlK7gKGCBYzQ9ECNu2c">
                    <GoogleMap
                        onLoad={onLoadGoogleMap}
                        onCenterChanged={() => { handleMapCenterChanged(); }}
                        id='map'
                        mapContainerStyle={mapContainerStyle}
                        center={center}
                        zoom={zoom}
                        options={{
                            styles: googleMapStyle,
                            disableDefaultUI: true
                        }}>
                    </GoogleMap>
                </LoadScriptNext>
            </div>
        </>
    );
};

export default ParkingSpotMap;