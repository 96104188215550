import { useAuth0 } from '@auth0/auth0-react';
import { createOrganization } from 'api/organization';
import { createUser, getUser } from 'api/users';
import ButtonText from 'components/common/buttonText';
import DropdownSelector from 'components/common/dropdownSelector/dropdownSelector';
import InputEmail from 'components/common/inputEmail';
import InputNumber from 'components/common/inputNumber';
import InputText from 'components/common/inputText';
import Logo from 'components/common/logo';
import { useFrontDesk } from 'context/frontDesk';
import { useMessages } from 'context/messages';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const RegisterPage = () => {

    const { t } = useTranslation();
    const { getAccessTokenSilently, user } = useAuth0();
    const [phoneCountryCode, setPhoneCountryCode] = useState('+46');
    const navigate = useNavigate();
    const { showToast } = useMessages();
    const { setUser } = useFrontDesk();
    const [isSaving, setIsSaving] = useState(false);

    const countries = [
        { value: 'US', title: 'United States' },
        { value: 'SE', title: 'Sverige' },
        { value: 'NO', title: 'Norge' },
        { value: 'DK', title: 'Danmark' },
        { value: 'FI', title: 'Suomi' },
        { value: 'DE', title: 'Deutschland' },
        { value: 'FR', title: 'France' },
        { value: 'GB', title: 'Great Britain' },
        { value: 'PL', title: 'Polska' },
        { value: 'IT', title: 'Italia' },
        { value: 'ES', title: 'España' },
        { value: 'GR', title: 'Greece' },
        { value: 'AT', title: 'Österreich' },
        { value: 'RO', title: 'România' },
        { value: 'NL', title: 'Nederland' },
        { value: 'BE', title: 'Belgique' },
        { value: 'HU', title: 'Magyarország' },
        { value: 'CH', title: 'Schweiz' },
        { value: 'IS', title: 'Ísland' },
        { value: 'LT', title: 'Lietuva' },
        { value: 'LV', title: 'Latvija' },
        { value: 'EE', title: 'Eesti' },
        { value: 'PT', title: 'Portugal	' },
    ].sort((a, b) => (a.title > b.title));

    const [userData, setUserData] = useState({
        email: '',
        phone: '',
        languageCode: 'en'
    });

    const [organizationData, setOrganizationData] = useState({
        name: '',
        countryCode: 'SE',
        organizationType: 'company',
        organizationNumber: '',
        address: '',
        zipCode: '',
        city: '',
        phone: '',
        email: '',
    });

    useEffect(() => {
        setUserData({ ...userData, email: user.email });
        setOrganizationData({ ...organizationData, email: user.email });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRegister = async () => {

        if (isSaving) {
            return;
        }

        if (
            !userData.email ||
            !userData.phone ||
            !organizationData.name ||
            !organizationData.address ||
            !organizationData.zipCode ||
            !organizationData.city) {
            showToast(t('registerPage.fieldsMissingTitle'), t('registerPage.fieldsMissingDescription'), 'error');
            return;
        }

        if (userData.countryCode === 'SE') {
            userData.languageCode = 'sv';
        }

        try {
            setIsSaving(true);
            const phoneNumber = `${phoneCountryCode}${userData.phone}`;
            const token = await getAccessTokenSilently();
            const createUserResult = await createUser(token, { ...userData, phone: phoneNumber, firstName: 'Front', lastName: 'Desk'  });
            const createOrganizationResult = await createOrganization(token, { ...organizationData, phone: phoneNumber });
            const userResult = await getUser(token);

            if (createUserResult &&
                !createUserResult.error &&
                createOrganizationResult &&
                !createOrganizationResult.error &&
                userResult &&
                !userResult.error) {
                setUser(userResult);
                navigate(`/${createOrganizationResult.organizationExtId}`);
            } else {
                console.error(createUserResult);
                console.error(createOrganizationResult);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }
        } catch (error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        } finally {
            setIsSaving(false);
        }
    }

    return (
        <div className="flex flex-col h-screen items-center">
            <div className="mt-12">
                <Logo mode='dark' />
            </div>
            <h1 className="text-2xl font-medium mt-12 pb-1">{t('registerPage.title')}</h1>
            <p>{t('registerPage.description')}</p>
            <div className="flex flex-col container">
                <div className="flex gap-x-6 mt-12">
                    <div className="flex flex-col w-1/2">
                        <InputEmail
                            title={t('registerPage.companyName')}
                            autoComplete={'organization'}
                            value={organizationData.name}
                            onChange={(value) => { setOrganizationData({ ...organizationData, name: value }) }} />
                    </div>
                    <div className="flex flex-col w-1/2">
                        <InputText
                            title={t('registerPage.organizationNumber')}
                            value={organizationData.organizationNumber}
                            onChange={(value) => { setOrganizationData({ ...organizationData, organizationNumber: value }) }} />
                    </div>
                </div>
                <div className="flex gap-x-6 mt-6">
                    <div className="flex flex-col w-1/2">
                        <InputEmail
                            title={t('registerPage.email')}
                            value={userData.email}
                            onChange={(value) => {
                                setOrganizationData({ ...organizationData, email: value });
                                setUserData({ ...userData, email: value });
                            }} />
                    </div>
                    <div className="flex flex-col w-1/2">
                        <span className="font-medium">{t('registerPage.phone')}</span>
                        <div className="flex gap-x-3 flex-row">
                            <div className="flex min-w-[96px]">
                                <DropdownSelector options={[
                                    { title: '+46', value: '+46' },
                                    { title: '+47', value: '+47' },
                                    { title: '+45', value: '+45' },
                                    { title: '+358', value: '+358' }]}
                                    value={phoneCountryCode}
                                    onChange={(value) => { setPhoneCountryCode(value); }}
                                    defaultValue='+46' />
                            </div>
                            <InputNumber
                                value={userData.phone}
                                onChange={(value) => {
                                    setUserData({ ...userData, phone: value })
                                    setOrganizationData({ ...organizationData, phone: value })
                                }} />
                        </div>
                    </div>
                </div>
                <div className="flex gap-x-6 mt-6">
                    <div className="flex flex-col w-1/2">
                        <InputText
                            autoComplete={'street-address'}
                            title={t('registerPage.address')}
                            value={organizationData.address}
                            onChange={(value) => { setOrganizationData({ ...organizationData, address: value }) }} />
                    </div>
                    <div className="flex flex-col w-1/2">
                        <InputText
                            autoComplete={'postal-code'}
                            title={t('registerPage.zipCode')}
                            value={organizationData.zipCode}
                            onChange={(value) => { setOrganizationData({ ...organizationData, zipCode: value }) }} />
                    </div>
                </div>
                <div className="flex gap-x-6 mt-6">
                    <div className="flex flex-col w-1/2">
                        <InputText
                            autoComplete={'address-level2'}
                            title={t('registerPage.city')}
                            value={organizationData.city}
                            onChange={(value) => { setOrganizationData({ ...organizationData, city: value }) }} />
                    </div>
                    <div className="flex flex-col w-1/2">
                        <DropdownSelector
                            title={t('registerPage.country')}
                            disabled={true}
                            options={countries}
                            defaultValue='SE'
                            value={organizationData.country}
                            onChange={(value) => {
                                setOrganizationData({ ...organizationData, country: value });
                                setUserData({ ...userData, countryCode: value });
                            }} />
                    </div>
                </div>
                <div className="flex justify-center mt-12">
                    <ButtonText
                        onClick={() => { handleRegister() }}
                        className="max-w-sm w-full"
                        isLoading={isSaving}>{t('registerPage.register')}</ButtonText>
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;