const BASE_URL = `${process.env.REACT_APP_API_VEHICLES_BASE_URL}/Vehicles`

export const createVehicle = async (token, vehicle) => {
    const response = await fetch(BASE_URL, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify(vehicle)
    })

    if (response.status === 200) {
        return response.json();
    }

    throw response;
}