import React, { useState, useEffect } from 'react';
import Toast from 'components/messages/toast';
import { useMessages } from "context/messages";
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from 'components/common/loading';
import { useTranslation } from 'react-i18next';
import Logo from 'components/common/logo';

const DefaultLayout = () => {

    const { toast } = useMessages();
    const { isLoading, isAuthenticated } = useAuth0();
    const navigate = useNavigate();
    const [isReady, setIsReady] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (isReady && !isAuthenticated) {
            console.log('user is not authenticated, redirecting to login page...')
            navigate('/');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReady, isAuthenticated]);

    useEffect(() => {
        if (!isLoading) {
            setIsReady(true);
        }
    }, [isLoading]);

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="absolute top-12">
                    <Logo mode='dark' />
                </div>
                <Loading title={t('generic.loading')} />
            </div>
        );
    }

    return (
        <>
            <Outlet />
            <Toast 
                display={toast?.display} 
                title={toast?.title} 
                description={toast?.description} 
                template={toast?.template} />
        </>
    );
};

export default DefaultLayout;