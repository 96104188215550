import React, { useEffect, useState } from 'react';
import TableItemContextMenu from 'components/common/tableItemContextMenu';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ThreeDotsIcon } from "assets/icons/ico_three_dots_green.svg";
import { ReactComponent as EditIcon } from "assets/icons/ico_pen_green.svg";
import { ReactComponent as SaveIcon } from "assets/icons/ico_check.svg";
import { ReactComponent as CloseIcon } from "assets/icons/ico_menu_close_green.svg";
import { useMessages } from 'context/messages';
import { useAuth0 } from '@auth0/auth0-react';
import { cancelAndRefundBooking } from 'api/payments';
import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';
import Lottie from 'lottie-react';
import { updateBooking } from 'api/bookings';
import { createVehicle } from 'api/vehicles';
import InputText from "components/common/inputText";

const BookingsItem = ({ booking, bookings, setBookings }) => {
    const { t } = useTranslation();
    const [showMenu, setShowMenu] = useState();
    const { showToast } = useMessages();
    const { getAccessTokenSilently } = useAuth0();
    const [isCancellingBooking, setIsCancellingBooking] = useState(false);
    const [isEditingBooking, setIsEditingBooking] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [registrationIdentifier, setRegistrationIdentifier] = useState(booking.registrationIdentifier);

    useEffect(() => {
        setRegistrationIdentifier(booking.registrationIdentifier);
    }, [booking])

    const formatDate = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);

        const sameDay = startDate.getDate() === endDate.getDate() &&
            startDate.getMonth() === endDate.getMonth() &&
            startDate.getFullYear() === endDate.getFullYear();

        let formattedDate;

        if (sameDay) {
            formattedDate = `${startDate.toLocaleString('default', { month: 'short' })} ${('0' + startDate.getDate()).slice(-2)} ${('0' + startDate.getHours()).slice(-2)}:${('0' + startDate.getMinutes()).slice(-2)}-${('0' + endDate.getHours()).slice(-2)}:${('0' + endDate.getMinutes()).slice(-2)}`;
        } else {
            formattedDate = `${startDate.toLocaleString('default', { month: 'short' })} ${('0' + startDate.getDate()).slice(-2)} ${('0' + startDate.getHours()).slice(-2)}:${('0' + startDate.getMinutes()).slice(-2)} - ${endDate.toLocaleString('default', { month: 'short' })} ${('0' + endDate.getDate()).slice(-2)} ${('0' + endDate.getHours()).slice(-2)}:${('0' + endDate.getMinutes()).slice(-2)}`;
        }

        return formattedDate;
    }

    const canCancelBooking = (start) => {
        const startDate = new Date(start);
        const currentDate = new Date();

        return currentDate < startDate;
    }


    const handleCancelBooking = async () => {
        if (!canCancelBooking(booking.startDateTime)) {
            showToast(t('bookingsTable.cannotCancelBookingTitle'), t('bookingsTable.cannotCancelBookingDescription'), 'error');
            setShowMenu(false);
            return;
        } else {
            setIsCancellingBooking(true);
            setShowMenu(false);
            try {
                const token = await getAccessTokenSilently();
                const result = await cancelAndRefundBooking(token, { bookingExtId: booking.bookingExtId });

                if (result && !result.error) {
                    const updatedBooking = result.booking;
                    const updatedBookings = bookings.map(booking => {
                        if (booking.bookingExtId === updatedBooking.bookingExtId) {
                            booking.startDateTime = updatedBooking.startDateTime;
                            booking.endDateTime = updatedBooking.endDateTime;
                            console.log(booking);
                        }
                        return booking;
                    });
                    setBookings([...updatedBookings]);
                    showToast(t('bookingsTable.bookingCancelledTitle'), t('bookingsTable.bookingCancelledDescription'), 'success');
                } else {
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            } finally {
                setIsCancellingBooking(false);
            }
        }
    }

    const getStatus = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const currentDate = new Date();

        if (currentDate < startDate) {
            return t('bookingsTable.statusFuture');
        } else if (currentDate >= startDate && currentDate <= endDate) {
            return t('bookingsTable.statusOngoing');
        } else {
            return t('bookingsTable.statusCompleted');
        }
    }

    const handleUpdateBooking = async () => {
        try {
            setEditMode(false);
            setIsEditingBooking(true);
            
            const token = await getAccessTokenSilently();
            const vehicleResult = await createVehicle(
                token,
                {
                    vehicleTypeId: 1,
                    vehicleIdentification: 'FrontDesk vehicle',
                    registrationIdentifier: registrationIdentifier
                });

            if (vehicleResult?.error) {
                console.error(vehicleResult);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                return;
            }

            const result = await updateBooking(token, { bookingExtId: booking.bookingExtId, vehicleExtId: vehicleResult.vehicleExtId });

            if (result && !result.error) {
                const updatedBooking = result;
                const updatedBookings = bookings.map(booking => {
                    if (booking.bookingExtId === updatedBooking.bookingExtId) {
                        booking.registrationIdentifier = updatedBooking.registrationIdentifier;
                        console.log(booking);
                    }
                    return booking;
                });
                setBookings([...updatedBookings]);
                showToast(t('bookingsTable.bookingUpdatedTitle'), t('bookingsTable.bookingUpdatedDescription'), 'success');
            } else {
                console.error(result);
                setRegistrationIdentifier(booking.registrationIdentifier);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }
        } catch (error) {
            console.error(error);
            setRegistrationIdentifier(booking.registrationIdentifier);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        } finally {
            setIsEditingBooking(false);
        }
    }

    return (
        <tr className="h-16 border-b-[1px] border-airpark-gray-300">
            <td className="pl-6 whitespace-nowrap">{booking.extendedFirstName}</td>
            <td className="pl-6 whitespace-nowrap">{booking.extendedLastName}</td>
            <td className="pl-6 whitespace-nowrap">
                <a href={`mailto:${booking.extendedEmail}`}>{booking.extendedEmail}</a>
            </td>
            <td className="pl-6 whitespace-nowrap">{booking.extendedPhone}</td>
            <td className="pl-6 whitespace-nowrap">
                {editMode &&
                    <InputText
                        value={registrationIdentifier ?? ''}
                        onChange={(value) => { setRegistrationIdentifier(value) }}
                        required={true} />
                }
                {!editMode && registrationIdentifier}
            </td>
            <td className="pl-6 whitespace-nowrap">{booking.parkingAreaName}</td>
            <td className="pl-6 whitespace-nowrap">{booking.isNumbered ? booking.spotIdentifier : t('bookingsTable.areaParking')}</td>
            <td className="pl-6 whitespace-nowrap">{formatDate(booking.startDateTime, booking.endDateTime)}</td>
            <td className="pl-6 pr-6 whitespace-nowrap">{getStatus(booking.startDateTime, booking.endDateTime)}</td>
            <td className="border-l-[1px] border-airpark-gray-300 w-[72px] min-w-[72px] relative">
                {isEditingBooking &&
                    <div className="flex h-16 w-full justify-center items-center">
                        <Lottie className="h-6 w-6" animationData={animSpinnerGreen} loop={true} />
                    </div>
                }
                {!isEditingBooking && editMode && booking.registrationIdentifier !== registrationIdentifier &&
                    <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); handleUpdateBooking(); }}>
                        <SaveIcon />
                    </button>
                }
                {!isEditingBooking && editMode && booking.registrationIdentifier === registrationIdentifier &&
                    <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); setEditMode(false); }}>
                        <CloseIcon />
                    </button>
                }
                {!isEditingBooking && !editMode &&
                    <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); setEditMode(true); }}>
                        <EditIcon />
                    </button>
                }
            </td>
            <td className="border-l-[1px] border-airpark-gray-300 w-[72px] min-w-[72px] relative">
                {isCancellingBooking &&
                    <div className="flex h-16 w-full justify-center items-center">
                        <Lottie className="h-6 w-6" animationData={animSpinnerGreen} loop={true} />
                    </div>
                }
                {!isCancellingBooking &&
                    <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); setShowMenu(!showMenu); }}>
                        <ThreeDotsIcon className="block" />
                    </button>
                }
                {showMenu &&
                    <TableItemContextMenu
                        onCloseClick={() => { setShowMenu(false); }}
                        onDeleteClick={() => {
                            handleCancelBooking();
                        }}
                        deleteTitle={t('bookingsTable.cancelBooking')}>
                    </TableItemContextMenu>
                }
            </td>
        </tr>
    );
};

export default BookingsItem;