const BASE_URL = `${process.env.REACT_APP_API_USERS_BASE_URL}/Organizations`

export const createOrganization = async (token, organization) => {
    const response = await fetch(BASE_URL + '/create-organization', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify(organization)
    })

    if (response.status === 200) {
        return response.json();
    }

    throw response;
}

export const getOrganizations = async (token) => {
    const response = await fetch(BASE_URL + '/organizations-for-user', {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    })

    if (response.status === 200) {
        return response.json();
    }

    throw response
}

export const updateOrganization = async (token, organization) => {
    const response = await fetch(BASE_URL + '/update-organization', {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${token}`
      },
      body: JSON.stringify(organization)
    })
  
    if (response.status === 200) {
      return response.json();
    }
  
    throw response;
  }


export const getAvailableVatPercentagesForOrganization = async (token, organizationExtId) => {
    const response = await fetch(BASE_URL + `/get-available-vat-percentages-for-organization/${organizationExtId}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${token}`
      }
    })
  
    if (response.status === 200) {
      return response.json();
    }
  
    throw response;
  }

  export const getOrganizationForUser = async (token, organizationExtId) => {
    const response = await fetch(BASE_URL + `/organization/${organizationExtId}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${token}`
      }
    })
  
    if (response.status === 200) {
      return response.json();
    }
  
    throw response;
  }