import LeftMenu from 'components/common/leftMenu';
import Loading from 'components/common/loading';
import Logo from 'components/common/logo';
import TopBar from 'components/common/topBar';
import { useFrontDesk } from 'context/frontDesk';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const FrontDeskLayout = () => {

    const { user: auth0User } = useAuth0();
    const userRoles = auth0User['https://airpark.app/roles'];

    const { user, isLoadingUser } = useFrontDesk();
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoadingUser && user?.error) {
            if (user?.error === 'USER_MISSING') {
                console.log('user is missing, redirecting to register page...');
                navigate('/register');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, isLoadingUser]);

    if (!userRoles.includes('frontdesk')) {
        return (
        <div className="flex flex-col min-h-screen overflow-x-hidden relative gap-y-6 align-middle items-center">
            <h1>Access denied</h1>
        </div>);
    }

    if (isLoadingUser) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="absolute top-12">
                    <Logo mode='dark' />
                </div>
                <Loading title={t('generic.loading')} />
            </div>
        );
    }

    return (
        <div className="flex flex-col min-h-screen overflow-x-hidden relative gap-y-6">
            <div className="flex flex-col">
                <TopBar />
            </div>
            <div className="flex grow pl-6 pb-6">
                <LeftMenu />
                <div className="flex flex-col w-full overflow-x-hidden pr-6 pl-6">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default FrontDeskLayout;