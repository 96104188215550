import { useAuth0 } from "@auth0/auth0-react";
import { getOrganizations } from "api/organization";
import { getUser } from "api/users";
import React, { useState, useContext, useEffect } from "react";

export const FrontDeskContext = React.createContext({});
export const useFrontDesk = () => useContext(FrontDeskContext);

export const FrontDeskProvider = ({ children }) => {

    const [user, setUser] = useState({});
    const [organizations, setOrganizations] = useState([]);
    const [isLoadingUser, setIsLoadingUser] = useState(true);
    const [isRegistered, setIsRegistered] = useState();

    const { getAccessTokenSilently, isLoading, isAuthenticated } = useAuth0();

    useEffect(() => {
        const load = async () => {
            try {
                const token = await getAccessTokenSilently();
                const userResult = await getUser(token);
                const organizationsResult = await getOrganizations(token);
                
                if (userResult && !userResult.error) {
                    setUser(userResult);
                    setIsRegistered(false);
                } else if (userResult.error === 'USER_MISSING') {
                    setIsRegistered(false);
                }

                if (organizationsResult && !organizationsResult.error) {
                    setOrganizations(organizationsResult);
                } else {
                    console.error(organizationsResult);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoadingUser(false);
            }
        }

        if (!isLoading && isAuthenticated) {
            load();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isAuthenticated]);

    return (
        <FrontDeskContext.Provider
            value={{
                user,
                organizations,
                setUser,
                isLoadingUser,
                isRegistered
            }}>
            {children}
        </FrontDeskContext.Provider>
    );
}