import { ReactComponent as HomeIcon } from 'assets/icons/ico_home.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/ico_settings.svg';
import { ReactComponent as BookingsIcon } from 'assets/icons/ico_bookings.svg';

export const leftMenuItems =
    [
        { translationKey: 'leftMenu.home', icon: <HomeIcon className="w-6 h-6" />, to: '' },
        {
            translationKey: 'leftMenu.book', icon: <BookingsIcon className="w-6 h-6" />, to: 'book', subItems: [
                { translationKey: 'leftMenu.bookings', to: 'book/bookings' },
            ]
        },
        { translationKey: 'leftMenu.settings', icon: <SettingsIcon className="w-6 h-6" />, to: 'settings' },
    ];