import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "components/common/scrollToTop";
import LoginPage from "pages/login";
import DefaultLayout from "layouts/defaultLayout";
import HomePage from "pages/home";
import AuthenticationCallbackPage from "pages/authenticationCallback/authenticationCallback";
import FrontDeskLayout from "layouts/frontDeskLayout";
import RegisterPage from "pages/register";
import BookPage from "pages/book";
import BookingsPage from "pages/bookings";
import SelectOrganizationPage from "pages/selectOrganization";
import SettingsPage from "pages/settings";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route element={<DefaultLayout />}>
            {/* Login and authentication */}
            <Route path="/" element={<LoginPage />} />
            <Route path="authentication-callback" element={<AuthenticationCallbackPage />} />
            <Route path="select-organization" element={<SelectOrganizationPage />} />
            <Route path="register" element={<RegisterPage />} />
            {/* FrontDesk application */}
            <Route path=":organizationExtId" element={<FrontDeskLayout />}>
              <Route path="" element={<HomePage />} />
              <Route path="book" element={<BookPage />} />
              <Route path="book/bookings" element={<BookingsPage />} />
              <Route path="settings" element={<SettingsPage />} />
            </Route>
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
