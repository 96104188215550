import { useAuth0 } from '@auth0/auth0-react';
import Alert from 'components/common/alert';
import ButtonText from 'components/common/buttonText';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as AirParkLogo } from 'assets/icons/ico_airpark_logo.svg';
import { getOrganizations } from 'api/organization';
import Loading from 'components/common/loading';
import { useFrontDesk } from 'context/frontDesk';

const AuthenticationCallbackPage = () => {

    const { isLoading, isAuthenticated, user, logout, getAccessTokenSilently } = useAuth0();
    const { isRegistered, isLoadingUser } = useFrontDesk();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const isUserInRole = (role) => {
        return user?.['https://airpark.app/roles']?.includes(role);
    };

    useEffect(() => {
        const load = async () => {
            const token = await getAccessTokenSilently();
            const organizations = await getOrganizations(token);

            if (organizations?.length > 1) {
                navigate('/select-organization');
            } else if (organizations.length === 1) {
                navigate(`/${organizations[0].organizationExtId}`);
            }
        }

        if (!isLoading && isAuthenticated && isUserInRole('frontdesk')) {
            load();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isAuthenticated]);

    useEffect(() => { 
        if (!isRegistered && !isLoadingUser) {
            navigate('/register');
        }
    }
    , [isRegistered, isLoadingUser])

    if (!isLoading && isAuthenticated && !isUserInRole('frontdesk')) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="flex flex-row items-center gap-x-3 absolute top-12">
                    <AirParkLogo className="w-[160px]" />
                    <p className="text-airpark-oil text-2xl">FrontDesk</p>
                </div>
                <div className="flex flex-col max-w-sm w-full gap-y-6">
                    <Alert
                        title={t('authenticationCallbackPage.frontDeskRequired')}
                        description={t('authenticationCallbackPage.frontDeskRequiredDescription', { email: user.email })}
                        template='error' />
                    <ButtonText onClick={() => { logout(); }}>{t('generic.signout')}</ButtonText>
                </div>
            </div>
        )
    } else {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="flex flex-row items-center gap-x-3 absolute top-12">
                    <AirParkLogo className="w-[160px]" />
                    <p className="text-airpark-oil text-2xl">FrontDesk</p>
                </div>
                <Loading />
            </div>);
    }
};

export default AuthenticationCallbackPage;