import { useFrontDesk } from 'context/frontDesk';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowRight } from 'assets/icons/ico_next_green.svg';
import { useNavigate } from 'react-router-dom';
import Logo from 'components/common/logo';
import Loading from 'components/common/loading';

const SelectOrganizationPage = () => {

    const { organizations, isLoadingUser } = useFrontDesk();
    const { t } = useTranslation();
    const navigate = useNavigate();

    if (organizations && !isLoadingUser) {
        return (
            <div className="flex h-screen items-center justify-center">
                <div className="flex flex-col max-w-sm w-full">
                    <h1 className="text-2xl font-medium mb-4">{t('selectOrganizationPage.title')}</h1>
                    <span className="font-base text-base mb-6">{t('selectOrganizationPage.description')}</span>
                    <div className="flex flex-col rounded-lg shadow-md pt-2 pb-2">
                        <ul className="flex flex-col w-full">
                            {organizations.map(organization => (
                                <OrganizationItem key={organization.organizationExtId} organization={organization} navigate={navigate} />
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="absolute top-12">
                    <Logo mode='dark' />
                </div>
                <Loading title={t('generic.loading')} />
            </div>
        );
    }
};

const OrganizationItem = ({ organization, navigate }) => {
    return (
        <button onClick={() => { navigate(`/${organization.organizationExtId}`) }} className="flex items-center justify-between h-11 border-slate-200 border-b-[1px] last:border-b-0 pl-6 pr-6 cursor-pointer">
            <span className="truncate max-w-[268px]">{organization.name}</span>
            <ArrowRight className="text-green-500" />
        </button>
    );
}

export default SelectOrganizationPage;