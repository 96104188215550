import React from 'react';
import { useTranslation } from "react-i18next";

import { ReactComponent as InfoIcon } from 'assets/icons/ico_info_circle_blue.svg';
import BookingsItem from './bookingsItem';

const BookingsTable = ({ bookings, setBookings, noFilterResults }) => {
    const { t } = useTranslation();

    if (!bookings) {
        return (null);
    }

    return (
        <>
            <div className="-mr-6 -ml-6 airpark-scroll overflow-x-scroll">
                <table className="table-auto w-full">
                    <thead className=''>
                        <tr className="text-left bg-airpark-gray-300 h-11">
                            <th className="pl-6 whitespace-nowrap">{t('bookingsTable.firstName')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('bookingsTable.lastName')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('bookingsTable.email')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('bookingsTable.phone')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('bookingsTable.vehicleIdentificationNumber')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('bookingsTable.parkingArea')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('bookingsTable.parkingSpot')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('bookingsTable.period')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('bookingsTable.status')}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {bookings && bookings.length > 0 && bookings.sort((a, b) => new Date(b.startDateTime) - new Date(a.startDateTime)).map((booking, i) => {
                            return (<BookingsItem
                                key={i}
                                booking={booking}
                                bookings={bookings}
                                setBookings={setBookings} />)
                        })}
                        {bookings && bookings.length < 1 &&
                            <tr className="h-16">
                                <td className="text-center" colSpan={4}>
                                    <div className="flex pl-6 gap-3 flex-row items-center justify-left">
                                        <InfoIcon className="h-6 w-6" />
                                        {noFilterResults &&
                                            <span>{t('bookingsTable.noBookingsWithFilter')}</span>
                                        }
                                        {!noFilterResults &&
                                            <span>{t('bookingsTable.noBookings')}</span>
                                        }
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default BookingsTable;