import moment from 'moment';

export const getFixedStartTime = (date, parkingArea) => {
    const dayOfTheWeek = moment(date).isoWeekday();
    let fixedTime;

    switch (dayOfTheWeek) {
        case 1:
            fixedTime = parkingArea?.mondayFixedStartTime;
            break;
        case 2:
            fixedTime = parkingArea?.tuesdayFixedStartTime;
            break;
        case 3:
            fixedTime = parkingArea?.wednesdayFixedStartTime;
            break;
        case 4:
            fixedTime = parkingArea?.thursdayFixedStartTime;
            break;
        case 5:
            fixedTime = parkingArea?.fridayFixedStartTime;
            break;
        case 6:
            fixedTime = parkingArea?.saturdayFixedStartTime;
            break;
        case 7:
            fixedTime = parkingArea?.sundayFixedStartTime;
            break;
        default:
            break;
    }

    return fixedTime;
}

export const getFixedEndTime = (date, parkingArea) => {
    const dayOfTheWeek = moment(date).isoWeekday();
    let fixedTime;

    switch (dayOfTheWeek) {
        case 1:
            fixedTime = parkingArea?.mondayFixedEndTime;
            break;
        case 2:
            fixedTime = parkingArea?.tuesdayFixedEndTime;
            break;
        case 3:
            fixedTime = parkingArea?.wednesdayFixedEndTime;
            break;
        case 4:
            fixedTime = parkingArea?.thursdayFixedEndTime;
            break;
        case 5:
            fixedTime = parkingArea?.fridayFixedEndTime;
            break;
        case 6:
            fixedTime = parkingArea?.saturdayFixedEndTime;
            break;
        case 7:
            fixedTime = parkingArea?.sundayFixedEndTime;
            break;
        default:
            break;
    }

    return fixedTime;
}