import React from "react";
import { ReactComponent as ArrowDownIcon } from "assets/icons/ico_arrow_down_green.svg";
import Tooltip from "components/common/tooltip";

const DropdownSelector = ({ title, options, defaultValue, onChange, tooltip }) => {
    return (
        <div className="flex flex-col w-full">
            <div className="flex relative items-center">
                <span className="text-base font-medium pb-1">{title}</span>
                {tooltip &&
                    <Tooltip tooltip={tooltip} />
                }
            </div>
            <div className="flex w-full relative items-center">
                <select defaultValue={defaultValue} onChange={(e) => { onChange(e.target.value); }} className="w-full bg-airpark-gray-300 rounded-xl h-11 appearance-none pl-4 focus:outline-none truncate overflow-hidden pr-6">
                    {options && options.map((o, i) => {
                        return (
                            <option key={i} value={o.value}>{o.title}</option>
                        )
                    })}
                </select>
                <div className="flex absolute right-4 pointer-events-none">
                    <ArrowDownIcon className="h-5 w-5" />
                </div>
            </div>
        </div>
    );
}

export default DropdownSelector;