import { useAuth0 } from '@auth0/auth0-react';
import { getBookingsForOrganization } from 'api/bookings';
import DropDownMultiSelector from 'components/common/dropDownMultiSelector';
import InputSearch from 'components/common/inputSearch';
import Widget from 'components/common/widget';
import WidgetLoadingSkeleton from 'components/common/widgetLoadingSkeleton';
import BookingsTable from 'components/pageSpecific/bookings/bookingsTable';
import { useMessages } from 'context/messages';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const BookingsPage = () => {

    const { getAccessTokenSilently } = useAuth0();
    const { organizationExtId } = useParams();
    const { t } = useTranslation();
    const [bookings, setBookings] = useState();
    const [filteredBookings, setFilteredBookings] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const { showToast } = useMessages();
    const [searchText, setSearchText] = useState();
    const [filterBookingOptions, setFilterBookingOptions] = useState([
        { title: t('bookingsPage.upcoming'), value: 'upcoming', isChecked: false },
        { title: t('bookingsPage.ongoing'), value: 'ongoing', isChecked: false },
        { title: t('bookingsPage.finished'), value: 'finished', isChecked: false },]);
    const [noFilterResults, setNoFilterResults] = useState(false);

    useEffect(() => {
        const load = async () => {
            try {
                const token = await getAccessTokenSilently();
                const result = await getBookingsForOrganization(token, organizationExtId, 1, 10);

                if (result && !result.error) {
                    setBookings(result);
                } else {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            } finally {
                setIsLoading(false);
            }
        }

        if (organizationExtId) {
            load();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationExtId]);


    useEffect(() => {
        if (bookings) {
            let shouldFilter = false;

            filterBookingOptions.forEach((option) => {
                if (option.isChecked) {
                    shouldFilter = true;
                }
            });

            let temporaryBookings = [...bookings];

            if (!shouldFilter) {
                /* Exclude expired bookings by default */
                temporaryBookings = [...bookings.filter((booking) => {
                    return new Date(booking.endDateTime) > new Date();
                })];
            }

            if (shouldFilter) {
                temporaryBookings = [...bookings.filter((booking) => {

                    /* Upcoming bookings */
                    if (filterBookingOptions[0].isChecked && new Date(booking.startDateTime) > new Date()) {
                        return true;
                    }

                    /* Ongoing bookings */
                    if (filterBookingOptions[1].isChecked && new Date(booking.startDateTime) < new Date() && new Date(booking.endDateTime) > new Date()) {
                        return true;
                    }

                    /* Finished bookings */
                    if (filterBookingOptions[2].isChecked && new Date(booking.endDateTime) < new Date()) {
                        return true;
                    }

                    return false;
                })];
            }

            if (searchText) {
                temporaryBookings = [...temporaryBookings.filter((booking) => {
                    if (booking.extendedFirstName?.toLowerCase().includes(searchText.toLowerCase())) {
                        return true;
                    }

                    if (booking.extendedLastName?.toLowerCase().includes(searchText.toLowerCase())) {
                        return true;
                    }

                    if (booking.extendedEmail?.toLowerCase().includes(searchText.toLowerCase())) {
                        return true;
                    }

                    if (booking.extendedPhone?.toLowerCase().includes(searchText.toLowerCase())) {
                        return true;
                    }

                    if (booking.registrationIdentifier?.toLowerCase().includes(searchText.toLowerCase())) {
                        return true;
                    }

                    return false;
                })];
            }

            setNoFilterResults(shouldFilter && temporaryBookings?.length === 0 && bookings?.length > 0);
            setFilteredBookings(temporaryBookings);
        }
    }, [filterBookingOptions, searchText, bookings]);

    const handleFilterBookings = (option) => {
        const newOptions = [...filterBookingOptions];
        const optionIndex = newOptions.findIndex((opt) => opt.value === option.value);
        newOptions[optionIndex].isChecked = !newOptions[optionIndex].isChecked;
        setFilterBookingOptions(newOptions);
    }

    if (isLoading) {
        return <WidgetLoadingSkeleton />
    }

    return (
        <Widget>
            <div className="flex items-center justify-between">
                <div className="flex flex-col">
                    <h1 className="text-2xl font-medium mb-4">{t('bookingsPage.title')}</h1>
                    <span className="font-base text-base mb-6">{t('bookingsPage.description')}</span>
                </div>
                <div className="hidden md:flex gap-3">
                    <InputSearch placeHolder={t('generic.search')} value={searchText ?? ''} onChange={(value) => { setSearchText(value); }} />
                    <DropDownMultiSelector
                        options={filterBookingOptions}
                        onOptionSelected={(option) => { handleFilterBookings(option); }}
                    />
                </div>
            </div>

            <BookingsTable
                bookings={filteredBookings}
                setBookings={setFilteredBookings}
                noFilterResults={noFilterResults} />
        </Widget>
    );
};

export default BookingsPage;