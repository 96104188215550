import React from 'react';
import { useTranslation } from "react-i18next";
import InvoiceItem from './invoiceItem';
import ButtonText from 'components/common/buttonText';

const InvoicesTable = ({ invoices }) => {
    const { t } = useTranslation();

    if (!invoices) {
        return (null);
    }
    return (
        <div className="-mr-6 -ml-6 airpark-scroll overflow-x-scroll">
            
            <table className="table-auto w-full">
                <thead className=''>
                    <tr className="text-left bg-airpark-gray-300 h-11">
                        <th className="pl-6 whitespace-nowrap">{t('invoicesTable.id')}</th>
                        <th className="pl-6 whitespace-nowrap">{t('invoicesTable.invoiceDate')}</th>
                        <th className="pl-6 whitespace-nowrap">{t('invoicesTable.dueDate')}</th>
                        <th className="pl-6 whitespace-nowrap">{t('invoicesTable.amount')}</th>
                        <th className="pl-6 whitespace-nowrap">{t('invoicesTable.vat')}</th>
                        <th className="pl-6 whitespace-nowrap">{t('invoicesTable.status')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {invoices?.length > 0 && invoices.map((invoice, i) => {
                        return (<InvoiceItem
                            key={i}
                            invoice={invoice} />)
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default InvoicesTable;