import React, { useEffect, useState } from 'react';
import { useFrontDesk } from 'context/frontDesk';
import DesktopMenu from '../desktopMenu';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { getOrganizationForUser } from 'api/organization';

const TopBar = () => {

    const { organizations } = useFrontDesk();
    const [organization, setOrganization] = useState();
    const { getAccessTokenSilently } = useAuth0();
    const { organizationExtId } = useParams();

    useEffect(() => {
        const load = async () => {
            try {
                const token = await getAccessTokenSilently();
                const organizationResult = await getOrganizationForUser(token, organizationExtId);
                if (organizationResult && !organizationResult.error) {
                    setOrganization(organizationResult);
                } else {
                    console.error(organizationResult);
                }
            } catch (error) {
                console.error(error);
            }
        }

        if (organizationExtId) {
            load();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationExtId]);

    if (!organizations) {
        return null;
    }

    return (
       <DesktopMenu organization={organization} />
    );
};

export default TopBar;