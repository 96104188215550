import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import 'index.css';
import './i18n';
import { MessagesProvider } from "context/messages";
import { FrontDeskProvider } from "context/frontDesk";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    redirectUri={window.location.origin + '/authentication-callback'}>
    <FrontDeskProvider>
      <MessagesProvider>
        <App />
      </MessagesProvider>
    </FrontDeskProvider>
  </Auth0Provider>
);