import React from 'react';

const Widget = ({children}) => {
    return (
        <div className="flex flex-col w-full shadow-lg rounded-lg p-6 bg-white">
            {children}
        </div>
    );
};

export default Widget;