import moment from 'moment';

const BASE_URL = `${process.env.REACT_APP_API_MAPS_BASE_URL}/Map`

export const getParkingAreas = async (token, request) => {

    request.startDateTime.toJSON = function () { return `${moment(this).format('YYYY-MM-DD')}T${moment(this).format('HH:mm:ss')}`; }
    request.endDateTime.toJSON = function () { return `${moment(this).format('YYYY-MM-DD')}T${moment(this).format('HH:mm:ss')}`; }

    const response = await fetch(BASE_URL + '/parking-areas', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify(request)
    })

    if (response.status === 200) {
        return response.json();
    }

    throw response;
}