const BASE_URL = `${process.env.REACT_APP_API_PAYMENTS_BASE_URL}`

export const payBooking = async (token, booking) => {
    const response = await fetch(BASE_URL + '/Invoice/pay-booking', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify(booking)
    })

    if (response.status === 200) {
        return response.json();
    }

    throw response;
}

export const cancelAndRefundBooking = async (token, booking) => {
    const response = await fetch(BASE_URL + '/Refund/cancel-and-refund-booking', {
        method: 'PUT',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify(booking)
    })

    if (response.status === 200) {
        return response.json();
    }

    throw response;
}