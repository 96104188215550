import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'sv',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    generic: {
                        errorTitle: 'Something went wrong',
                        errorDescription: 'Something went wrong, please try again.',
                        missingKeyReplacement: '...',
                        yes: 'Yes',
                        no: 'No',
                        remove: 'Delete',
                        close: 'Close',
                        back: 'Back',
                        edit: 'Edit',
                        cancel: 'Cancel',
                        ok: 'OK',
                        save: 'Save',
                        update: 'Update',
                        email: 'E-mail',
                        phone: 'Phone number',
                        search: 'Search',
                        create: 'Create',
                        missingFieldsTitle: 'Fields missing',
                        missingFieldsDescription: 'One or more fields have missing or incorrect information',
                        saving: 'Saving',
                        uploadImageFiles: "Click to upload images or drag and drop PNG, JPG, JPEG or GIF",
                        uploadDocumentFiles: "Click to upload files or drag and drop GIF, JPG, JPEG, PDF or PNG",
                        warning: "Warning",
                        areyousure: "Are you sure?",
                        airPark: 'AirPark',
                        share: 'Share',
                        add: 'Add',
                        invite: 'Invite',
                        signin: 'Sign in',
                        signup: 'Sign up',
                        signout: 'Sign out',
                        firstName: 'First name',
                        lastName: 'Last name',
                        from: 'From',
                        to: 'To',
                        id: 'Id',
                        amount: 'Amount',
                        status: 'Status',
                        choose: 'Choose',
                        change: 'Change',
                        revoke: 'Revoke',
                        videoInstructions: 'Video instructions',
                        updated: 'Updated',
                        updatedDescription: 'Updated successfully',
                        loading: 'Loading',
                        next: 'Next',
                        chargingPost: 'Charge box',
                        filter: 'Filter',
                    },
                    homePage: {
                        title: 'AirPark FrontDesk',
                        description: 'AirPark FrontDesk is a web application that allows you to manage your bookings.',
                        latestInvoices: 'Latest invoices',
                        noInvoicesTitle: 'No invoices',
                        noInvoicesDescription: 'No invoices found',
                        downloadCSV: 'Download CSV'
                    },
                    bookPage: {
                        selectParkingAreaTitle: 'Select parking area',
                        selectParkingAreaDescription: 'Select parking area to book a parking spot',
                        rentalTime: 'Rental time',
                        selectedParkingAreaUnavailableForSelectedTimePeriodTitle: 'Parking area is unavailable',
                        selectedParkingAreaUnavailableForSelectedTimePeriodDescription: 'Parking area is unavailable for selected time period. Please select another parking area or another time.',
                        selectedparkingAreaIsFreeForSelectedTimePeriodTitle: 'Parking area is free',
                        selectedparkingAreaIsFreeForSelectedTimePeriodDescription: 'Parking area is free for selected time period. Please select another parking area or another time.',
                        startDate: 'Start date',
                        endDate: 'End date',
                        requiresAirPassTitle: 'Requires AirPass',
                        requiresAirPassDescription: 'This parking area requires AirPass. If the booking is made through FrontDesk, the customer must be issued a keycard or equivalent.',
                        book: 'Book',
                        cancel: 'Cancel',
                        parkingSpot: 'Spot',
                        firstName: 'First name',
                        lastName: 'Last name',
                        phone: 'Phone number',
                        email: 'E-mail',
                        vehicleIdentificationNumber: 'Vehicle identification number',
                        firstNamePlaceHolder: 'First name',
                        lastNamePlaceHolder: 'Last name',
                        bookingSuccessTitle: 'Booking successful',
                        bookingSuccessDescription: 'Booking successful. You can view your booking in the bookings section.',
                        parkingUnavailableTitle: 'Parking spot is unavailable',
                        parkingUnavailableDescription: 'Parking spot is unavailable for selected time period. Please select another parking spot or another time. If the problem persists please try to refresh the page and try again.',
                        invalidEmailTitle: 'Invalid e-mail',
                        invalidEmailDescription: 'The e-mail is not valid',
                        vehicleIdentificationNumberRequiredTitle: 'Vehicle identification number required',
                        vehicleIdentificationNumberRequiredDescription: 'Vehicle identification number is required to book a parking spot',
                    },
                    loginPage: {
                        login: 'Login',
                        title: 'AirPark FrontDesk',
                        description: 'Login to AirPark FrontDesk'
                    },
                    authenticationCallbackPage: {
                        frontDeskRequired: 'FrontDesk required',
                        frontDeskRequiredDescription: 'FrontDesk access is required to access this page. Your current user with e-mail {{email}} is not authorized. If you are a FrontDesk user, please contact us at hello@airpark.app to get access.',
                    },
                    leftMenu: {
                        home: 'Home',
                        bookings: 'Bookings',
                        settings: 'Settings',
                        book: 'Book',
                    },
                    unit: {
                        day: 'day',
                        hour: 'hour',
                        minute: 'min',
                    },
                    registerPage: {
                        firstName: 'First name',
                        lastName: 'Last name',
                        email: 'E-mail',
                        address: 'Address',
                        city: 'City',
                        zipCode: 'Zip code',
                        country: 'Country',
                        phone: 'Phone number',
                        companyName: 'Company name',
                        organizationNumber: 'Organization number',
                        register: 'Register',
                        title: 'Company registration',
                        description: 'To register your company, please fill in the information below.',
                        fieldsMissingTitle: 'Fields missing',
                        fieldsMissingDescription: 'One or more fields have missing or incorrect information',
                    },
                    selectOrganizationPage: {
                        title: 'Select organization',
                        description: 'Select organization to log in to',
                    },
                    bookingsPage: {
                        title: 'Bookings',
                        description: 'Here you can view your bookings.',
                        upcoming: 'Upcoming',
                        ongoing: 'Ongoing',
                        finished: 'Finished'
                    },
                    bookingsTable: {
                        firstName: 'First name',
                        lastName: 'Last name',
                        email: 'E-mail',
                        phone: 'Phone number',
                        period: 'Period',
                        parkingArea: 'Parking area',
                        parkingSpot: 'Parking spot',
                        status: 'Status',
                        vehicleIdentificationNumber: 'Vehicle identification number',
                        noBookings: 'No upcoming or ongoing bookings',
                        cancelBooking: 'Cancel booking',
                        cannotCancelBookingTitle: 'Cannot cancel booking',
                        cannotCancelBookingDescription: 'Cannot cancel a booking that is in progress or has ended.',
                        areaParking: 'Area parking',
                        bookingCancelledTitle: 'Booking cancelled',
                        bookingCancelledDescription: 'The booking is now cancelled.',
                        bookingUpdatedTitle: 'Booking updated',
                        bookingUpdatedDescription: 'Booking is now updated.',
                        noBookingsWithFilter: 'No bookings with filter',
                    },
                    settingsPage: {
                        title: 'Settings',
                        description: 'Manage the settings for your organization.',
                        name: 'Name',
                        organizationNumber: 'Organization number',
                        address: 'Address',
                        zipCode: 'Zip Code',
                        city: 'City',
                        country: 'Country',
                        phoneNumber: 'Phone number',
                        email: 'Email',
                        organizationUpdatedTitle: 'Updated',
                        organizationUpdatedDescription: 'Organization was updated successfully',
                        vatPercentage: 'VAT percentage',
                        defaultStartTime: 'Default start time',
                        defaultEndTime: 'Default end time',
                    },
                    topBar: {
                        organizations: "Organizations",
                        logout: 'Logout'
                    },
                    invoicesTable: {
                        id: 'Id',
                        invoiceDate: 'Invoice date',
                        dueDate: 'Due date',
                        amount: 'Amount inc. VAT',
                        vat: 'VAT',
                        status: 'Status',
                        downloadPdf: 'Download PDF',
                        pay: 'Pay',
                    }
                },
            },
            /* SWEDISH */
            sv: {
                translation: {
                    generic: {
                        errorTitle: 'Något gick fel',
                        errorDescription: 'Något gick fel, vänligen försök igen.',
                        missingKeyReplacement: '...',
                        yes: 'Ja',
                        no: 'Nej',
                        remove: 'Ta bort',
                        close: 'Stäng',
                        back: 'Tillbaka',
                        edit: 'Redigera',
                        cancel: 'Avbryt',
                        ok: 'OK',
                        save: 'Spara',
                        update: 'Uppdatera',
                        email: 'E-post',
                        phone: 'Telefonnummer',
                        search: 'Sök',
                        airPark: 'AirPark',
                        create: 'Skapa',
                        missingFieldsTitle: 'Fält saknas',
                        missingFieldsDescription: 'Ett eller flera fält saknar information eller är inte korrekt ifyllda',
                        saving: 'Sparar',
                        uploadImageFiles: "Klicka för att ladda upp bilder eller dra och släpp PNG, JPG, JPEG eller GIF",
                        uploadDocumentFiles: "Klicka för att ladda upp filer eller dra och släpp GIF, JPG, JPEG PDF, eller PNG",
                        warning: "Varning",
                        areyousure: "Är du säker?",
                        share: 'Dela',
                        add: 'Lägg till',
                        invite: 'Bjud in',
                        signin: 'Logga in',
                        signup: 'Registrera',
                        signout: 'Logga ut',
                        firstName: 'Förnamn',
                        lastName: 'Efternamn',
                        from: 'Från',
                        to: 'Till',
                        id: 'Id',
                        amount: 'Summa',
                        status: 'Status',
                        choose: 'Välj',
                        change: 'Ändra',
                        revoke: 'Avsluta',
                        videoInstructions: 'Videoinstruktioner',
                        updated: 'Uppdaterat',
                        updatedDescription: 'Uppdateratingen lyckades',
                        loading: 'Laddar',
                        next: 'Nästa',
                        chargingPost: 'Laddbox',
                        filter: 'Filtrera'
                    },
                    homePage: {
                        title: 'Hej, {{name}}!',
                        description: 'Här hittar du dina senaste fakturor.',
                        latestInvoices: 'Senaste fakturorna',
                        noInvoicesTitle: 'Inga fakturor',
                        noInvoicesDescription: 'Du har inga fakturor.',
                        downloadCSV: 'Ladda ner CSV'
                    },
                    bookPage: {
                        title: 'Boka parkering',
                        selectParkingAreaTitle: 'Välj parkeringsområde',
                        selectParkingAreaDescription: 'Välj parkeringsområde för att kunna boka parkering',
                        rentalTime: 'Hyrtid',
                        selectedParkingAreaUnavailableForSelectedTimePeriodTitle: 'Parkeringsområdet är inte tillgängligt',
                        selectedParkingAreaUnavailableForSelectedTimePeriodDescription: 'Parkeringsområdet är inte tillgängligt för den valda tiden. Vänligen välj ett annat parkeringsområde eller en annan tid.',
                        selectedparkingAreaIsFreeForSelectedTimePeriodTitle: 'Parkeringsområdet är gratis',
                        selectedparkingAreaIsFreeForSelectedTimePeriodDescription: 'Parkeringsområdet är gratis för den valda tiden. Vänligen välj ett annat parkeringsområde eller en annan tid.',
                        startDate: 'Startdatum',
                        endDate: 'Slutdatum',
                        requiresAirPassTitle: 'AirPass krävs',
                        requiresAirPassDescription: 'Detta parkeringsområde använder AirPass för in- och utfart. Eftersom bokningen genomförs via FrontDesk behöver kunden förses med ett nyckelkort eller motsvarande.',
                        book: 'Boka',
                        cancel: 'Avbryt',
                        parkingSpot: 'Plats',
                        firstName: 'Förnamn',
                        lastName: 'Efternamn',
                        phone: 'Telefonnummer',
                        email: 'E-post',
                        vehicleIdentificationNumber: 'Registreringsnummer',
                        firstNamePlaceHolder: 'Förnamn',
                        lastNamePlaceHolder: 'Efternamn',
                        bookingSuccessTitle: 'Bokningen lyckades',
                        bookingSuccessDescription: 'Bokningen lyckades. Du kan se din bokning under bokningar.',
                        parkingUnavailableTitle: 'Parkeringsplatsen är inte tillgänglig',
                        parkingUnavailableDescription: 'Parkeringsplatsen är inte tillgänglig för den valda tiden. Vänligen välj en annan parkeringsplats eller en annan tid. Pröva annars att uppdatera sidan och försök igen.',
                        invalidEmailTitle: 'Ogiltig e-postadress',
                        invalidEmailDescription: 'E-postadressen är inte giltig',
                        vehicleIdentificationNumberRequiredTitle: 'Registreringsnummer krävs',
                        vehicleIdentificationNumberRequiredDescription: 'Registreringsnummer krävs för att boka parkering',
                    },
                    loginPage: {
                        login: 'Logga in',
                        title: 'AirPark FrontDesk',
                        description: 'Inloggning till AirPark FrontDesk'
                    },
                    authenticationCallbackPage: {
                        frontDeskRequired: 'FrontDesk krävs',
                        frontDeskRequiredDescription: 'För att komma åt denna sida krävs FrontDesk. Din nuvarande användare med e-post {{email}} är inte behörig. Om du är en FrontDesk-användare, vänligen kontakta oss på hello@airpark.app.',
                    },
                    leftMenu: {
                        home: 'Hem',
                        bookings: 'Bokningar',
                        settings: 'Inställningar',
                        book: 'Boka'
                    },
                    unit: {
                        day: 'dygn',
                        hour: 'timme',
                        minute: 'min',
                    },
                    registerPage: {
                        firstName: 'Förnamn',
                        lastName: 'Efternamn',
                        email: 'E-post',
                        address: 'Adress',
                        city: 'Stad',
                        zipCode: 'Postnummer',
                        country: 'Land',
                        phone: 'Telefonnummer',
                        companyName: 'Företagsnamn',
                        organizationNumber: 'Organisationsnummer',
                        register: 'Registrera',
                        title: 'Företagsuppgifter',
                        description: 'För att kunna boka parkeringar behöver vi veta lite mer om ditt företag. Fyll i företagsuppgifterna nedan.',
                        fieldsMissingTitle: 'Fält saknas',
                        fieldsMissingDescription: 'Ett eller flera fält saknar information eller är inte korrekt ifyllda',
                    },
                    selectOrganizationPage: {
                        title: 'Välj organisation',
                        description: 'Välj organisation för att logga in med',
                    },
                    bookingsPage: {
                        title: 'Bokningar',
                        description: 'Här hittar du dina bokningar.',
                        upcoming: 'Kommande',
                        ongoing: 'Pågående',
                        finished: 'Avslutade',
                    },
                    bookingsTable: {
                        firstName: 'Förnamn',
                        lastName: 'Efternamn',
                        email: 'E-post',
                        phone: 'Telefonnummer',
                        period: 'Period',
                        parkingArea: 'Parkeringsområde',
                        parkingSpot: 'Plats',
                        status: 'Status',
                        vehicleIdentificationNumber: 'Reg.nummer',
                        noBookings: 'Inga kommande eller pågående bokningar',
                        cancelBooking: 'Avboka',
                        cannotCancelBookingTitle: 'Kan inte avboka',
                        cannotCancelBookingDescription: 'En pågående eller avslutad bokning kan inte avbokas.',
                        statusFuture: 'Kommande',
                        statusOngoing: 'Pågående',
                        statusCompleted: 'Avslutad',
                        areaParking: 'Områdesparkering',
                        bookingCancelledTitle: 'Avbokad',
                        bookingCancelledDescription: 'Bokningen är nu avbokad.',
                        bookingUpdatedTitle: 'Uppdaterad',
                        bookingUpdatedDescription: 'Bokningen är nu uppdaterad.',
                        noBookingsWithFilter: 'Inga bokningar med detta filter',
                    },
                    settingsPage: {
                        title: 'Inställningar',
                        description: 'Hantera inställningarna för din organisation.',
                        name: 'Namn',
                        organizationNumber: 'Organisations- eller personnummer',
                        address: 'Adress',
                        zipCode: 'Postnummer',
                        city: 'Stad',
                        country: 'Land',
                        phoneNumber: 'Telefonnummer',
                        email: 'E-post',
                        organizationUpdatedTitle: 'Uppdaterat',
                        organizationUpdatedDescription: 'Organisationen är nu uppdaterad',
                        stripeConfiguredTitle: 'Stripe konfigurerat',
                        stripeConfiguredDescription: 'Stripe är konfigurerat och kopplat till ditt AirPark-konto. Din organisation kan nu ta emot betalningar.',
                        vatPercentage: 'Moms i procent',
                        defaultStartTime: 'Standard starttid',
                        defaultEndTime: 'Standard sluttid',
                        frontDeskEmailConfirmationTextTitle: 'Information till kunden som skickas med bekräftelsemejlet',
                        frontDeskEmailConfirmationTextPlaceHolder: 'Exempelvis: Hej! Tack för din bokning. Vi ser fram emot att ha dig som gäst hos oss. Du når oss på telefon 08-123 456 78 eller via e-post hej@mittbolag.se om du har några frågor.',
                    },
                    topBar: {
                      organizations: "Organisationer",
                      logout: 'Logga ut'
                    },
                    invoicesTable: {
                        id: 'Id',
                        invoiceDate: 'Fakturadatum',
                        dueDate: 'Förfallodatum',
                        amount: 'Summa inkl. moms',
                        vat: 'Moms',
                        status: 'Status',
                        downloadPdf: 'Ladda ner PDF',
                        pay: 'Betala',
                    }
                },
            },
        }
    });
export default i18n;