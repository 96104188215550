const BASE_URL = `${process.env.REACT_APP_API_USERS_BASE_URL}/invoice`;

export const getInvoices = async (token, organizationExtId) => {
    const response = await fetch(BASE_URL + `/invoices-for-organization/${organizationExtId}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${token}`
      }
    })
  
    if (response.status === 200) {
      return response.json();
    }
  
    throw response
  }