import React from 'react';
import { ReactComponent as AirParkLogo } from 'assets/icons/ico_airpark_logo.svg';

const Logo = ({ mode }) => {
    return (
        <div className="flex flex-row items-center gap-x-3">
            <AirParkLogo className="w-[160px]" />
            <p className={`${mode === 'dark' ? 'text-airpark-oil' : 'text-white'} text-2xl`}>FrontDesk</p>
        </div>
    );
};

export default Logo;