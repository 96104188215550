import moment from 'moment';

const BASE_URL = `${process.env.REACT_APP_API_BOOKINGS_BASE_URL}/Bookings`

export const bookParkingSpot = async (token, booking) => {
    booking.startDateTime.toJSON = function () { return `${moment(this).format('YYYY-MM-DD')}T${moment(this).format('HH:mm:ss')}`; }
    booking.endDateTime.toJSON = function () { return `${moment(this).format('YYYY-MM-DD')}T${moment(this).format('HH:mm:ss')}`; }

    const response = await fetch(BASE_URL + '/parkingspot', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify(booking)
    })

    if (response.status === 200) {
        return response.json();
    }

    throw response;
}

export const bookParkingArea = async (token, booking) => {
    booking.startDateTime.toJSON = function () { return `${moment(this).format('YYYY-MM-DD')}T${moment(this).format('HH:mm:ss')}`; }
    booking.endDateTime.toJSON = function () { return `${moment(this).format('YYYY-MM-DD')}T${moment(this).format('HH:mm:ss')}`; }

    const response = await fetch(BASE_URL + '/parkingarea', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify(booking)
    })

    if (response.status === 200) {
        return response.json();
    }

    throw response;
}

export const getBookingsForOrganization = async (token, organizationExtId, page, take) => {
  const response = await fetch(BASE_URL + `/organization/${organizationExtId}/${page}/${take}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const updateBooking = async (token, booking) => {
  const response = await fetch(BASE_URL, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(booking)
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}